<template>
  <div>
    <h2 class="mb-4">
      Vamos criar um {{ parentParty ? "subevento" : "evento" }}?
    </h2>
    <v-stepper v-model="step" vertical class="rounded-xl">
      <v-stepper-step :step="1" :complete="1 < step"> Detalhes </v-stepper-step>
      <v-stepper-content :step="1">
        <v-card v-if="parentParty" class="d-flex align-center mb-4 gap-4" outlined>
          <div>
            <v-img
              height="60"
              :width="60 * (16 / 9)"
              :src="parentParty.cover"
              class="rounded-l"
              :aspect-ratio="16 / 9"
            />
          </div>
          <div>
            <span class="text-overline lh-1">Evento principal</span>
            <h5 class="mb-0">{{ parentParty.name }}</h5>
          </div>
        </v-card>

        <v-row v-if="!created" id="form-party-details">
          <v-col cols="12" md="4">
            <v-hover v-slot="{ hover }">
              <v-img :src="fileUrl" class="rounded" :aspect-ratio="16 / 9">
                <v-fade-transition leave-absolute>
                  <v-card
                    v-if="hover || !fileUrl"
                    @click="changeCover"
                    class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-h2 white--text justify-center align-center"
                    style="height: 100%; opacity: 0.8"
                  >
                    <v-icon class="white--text">mdi-camera</v-icon>
                  </v-card>
                </v-fade-transition>
              </v-img>
            </v-hover>
            <small class="text--secondary text-center d-block mt-2">
              A resolução recomendada é <b>1920x1080</b>. A capa sempre será
              exibida na proporção <b>16/9</b>.
            </small>
            <input
              type="file"
              accept="image/*"
              ref="coverInput"
              @change="onFileChange"
              v-show="false"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="party.name"
              :counter="50"
              :rules="rules.name"
              label="Nome do evento"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="party.subtitle"
              :counter="50"
              label="Subtítulo"
              dense
              outlined
            ></v-text-field>

            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="party.date"
                  label="Data"
                  outlined
                  type="datetime-local"
                  required
                  hide-details
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="party.endDate"
                  label="Data final"
                  outlined
                  type="datetime-local"
                  required
                  hide-details
                  :rules="rules.endDate"
                />
              </v-col>
            </v-row>

            <base-editor label="Descrição" v-model="party.description" />
          </v-col>
        </v-row>
        <v-alert v-if="error" type="error" class="mt-2">
          {{ error }}
        </v-alert>

        <div class="d-flex justify-end mb-2" v-if="!created">
          <v-btn
            color="primary"
            @click="saveParty"
            :loading="loading"
            class="ml-2"
            id="btn-save-party"
          >
            Criar Evento
          </v-btn>
        </div>
        <div v-else id="container-party-summary">
          <v-row>
            <v-col cols="12" sm="4">
              <v-img :src="fileUrl" class="rounded" :aspect-ratio="16 / 9" />
            </v-col>
            <v-col cols="12" sm="8">
              <h3 class="lh-8">
                {{ party.name }}
                <v-chip label small color="info">Evento privado</v-chip>
              </h3>
              <ul>
                <li>Local do evento: <b>não definido</b></li>
                <li>Faixa Etária: <b>18 anos</b></li>
                <li>
                  Política de reembolso:
                  <b>7 dias apos a compra e até 48h antes do evento</b>
                </li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn text block color="primary" @click="goToParty">
                Finalizar depois
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn block color="primary" @click="step++">
                Continuar configurando
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>

      <template v-for="s in steps">
        <v-stepper-step
          :key="`header-${s.step}`"
          :complete="s.step < step"
          :editable="!loading && created"
          :step="s.step"
          :icon="s.icon"
        >
          {{ s.name }}
          <small v-if="s.optional"> Opcional</small>
        </v-stepper-step>

        <v-stepper-content :key="'content-' + s.step" :step="s.step">
          <div :id="`container-party-${s.component}`">
            <component :is="s.component" :ref="s.component" :party="party" />

            <div class="d-flex justify-end mb-2">
              <v-btn v-if="step > 2" @click="stepBack" text :disabled="loading">
                Voltar
              </v-btn>
              <v-btn
                v-if="step < steps.length + 2"
                color="primary"
                @click="nextStep"
                class="ml-2"
                :loading="loading"
              >
                Próximo
              </v-btn>
            </div>
          </div>
        </v-stepper-content>
      </template>

      <!-- Finalização -->
      <v-stepper-step :step="steps.length + 2"> Finalizar </v-stepper-step>
      <v-stepper-content :step="steps.length + 2">
        <v-row id="container-party-done">
          <v-col cols="12" sm="4">
            <v-img :src="fileUrl" class="rounded" :aspect-ratio="16 / 9" />
          </v-col>
          <v-col cols="12" sm="8">
            <h3>
              {{ party.name }}
            </h3>
            <ul>
              <li>Sem setores criados</li>
              <li>Sem lotes criados</li>
            </ul>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn text block color="primary" @click="goToParty">
              Finalizar depois
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn block color="primary" @click="goToTicketManager">
              Configurar lotes
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PARTY from "@/services/admin/party";
import moment from "moment";

import Location from "@/components/admin/party/detailsComponents/Location.vue";
import Privacy from "@/components/admin/party/policies/Privacy.vue";
import AgeGroup from "@/components/admin/party/policies/AgeGroup.vue";
import Entrance from "@/components/admin/party/policies/Entrance.vue";
import Refund from "@/components/admin/party/policies/Refund.vue";
import SalesRestriction from "@/components/admin/party/policies/SalesRestriction.vue";

export default {
  components: {
    Location,
    Privacy,
    AgeGroup,
    Entrance,
    Refund,
    SalesRestriction,
  },
  metaInfo: {
    title: "Criar Evento",
  },
  data: () => ({
    loading: false,
    created: false,
    error: null,

    step: 1,
    steps: [
      {
        name: "Privacidade",
        icon: "mdi-security",
        component: "Privacy",
        save: true,
        step: 2,
      },
      {
        name: "Local",
        icon: "mdi-map-marker",
        component: "Location",
        optional: true,
        save: true,
        step: 3,
      },
      {
        name: "Faixa Etária",
        icon: "mdi-account-group",
        component: "AgeGroup",
        optional: true,
        save: true,
        step: 4,
      },
      {
        name: "Portaria",
        icon: "mdi-account-group",
        component: "Entrance",
        optional: true,
        save: true,
        step: 5,
      },
      {
        name: "Restrição de venda",
        icon: "mdi-lock",
        component: "SalesRestriction",
        optional: true,
        save: true,
        step: 6,
      },
      {
        name: "Reembolsos",
        icon: "mdi-cash",
        component: "Refund",
        optional: true,
        save: true,
        step: 7,
      },
    ],

    party: {
      name: "",
      description: "",
      date: null,
      endDate: null,
    },

    file: null,
    fileUrl: null,

    rules: {
      name: [
        (v) => !!v || "Name é obrigatório",
        (v) => (v && v.length <= 50) || "Name deve ter menos de 50 caracteres",
      ],
      subtitle: [
        (v) =>
          (v && v.length <= 30) || "Subtítulo deve ter menos de 30 caracteres",
      ],
      endDate: [(v) => !!v || "Data final é obrigatório"],
    },
  }),
  methods: {
    changeCover() {
      if (this.fileLoading) return;
      this.$refs.coverInput.click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (!file) return;
      this.fileUrl = URL.createObjectURL(file);
      this.file = file;
    },
    stepBack() {
      this.error = null;
      this.step--;
    },
    async nextStep() {
      try {
        this.loading = true;
        const component = this.steps.find(
          (s) => s.step === this.step
        ).component;
        await this.$refs[component][0].save();
        this.step++;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    goToParty() {
      if (!this.party.id) return;
      this.$root.$emit("finish-later");
      this.$router.push({
        name: "admin.party.details",
        params: { partyId: this.party.id },
      });
    },
    goToTicketManager() {
      if (!this.party.id) return;
      this.$root.$emit("finish-later");
      this.$router.push({
        name: "admin.party.management",
        params: { partyId: this.party.id },
      });
    },
    verifyData() {
      if (!this.party.name) throw new Error("Nome é obrigatório");
      if (!this.party.date) throw new Error("Data inicial é obrigatória");
      if (moment().isAfter(moment(this.party.date)))
        throw new Error("Data inicial não pode ser no passado");

      if (!this.party.endDate) throw new Error("Data final é obrigatória");
      if (moment(this.party.date).isAfter(moment(this.party.endDate)))
        throw new Error("Data final não pode ser antes da data inicial");

      if (!this.file) throw new Error("Capa é obrigatória");
    },
    async saveParty() {
      try {
        this.verifyData();
        this.error = null;
        this.loading = true;
        const { party } = await PARTY.create(this.selectedOrganization.id, {
          ...this.party,
          date: new Date(this.party.date).toISOString(),
          endDate: new Date(this.party.endDate).toISOString(),
          parentId: this.parentParty?.id,
        });

        await PARTY.cover(this.selectedOrganization.id, party.id, this.file);

        this.confetti();

        this.party = party;
        this.created = true;
        this.$root.$emit("party-created");
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    async confetti() {
      this.$confetti.start();
      await new Promise((r) => setTimeout(r, 1500));
      this.$confetti.stop();
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization", "partyById"]),
    parentParty() {
      if (!this.$route.query.parentId) return null;
      const parentParty = this.partyById(this.$route.query.parentId);
      if (!parentParty) return null;
      return parentParty;
    },
  },
  watch: {
    "party.date": {
      handler(val, oldVal) {
        if (!val) return;
        // set party endDate to 6 hours after party date only if
        // party endDate is not set OR
        // party endDate is set and is before party date OR
        // ELSE party endDate is set
        // set end date to same diff as old date
        if (
          !this.party.endDate ||
          (this.party.endDate && moment(this.party.endDate).isBefore(val))
        ) {
          this.party.endDate = moment(val)
            .add(6, "hours")
            .format("YYYY-MM-DDTHH:mm");
        } else {
          this.party.endDate = moment(this.party.endDate)
            .add(moment(val).diff(moment(oldVal)))
            .format("YYYY-MM-DDTHH:mm");
        }
      },
      immediate: true,
    },
    step(val) {
      const step = this.steps.find((s) => s.step === val);
      if (step) this.$root.$emit(`create-party-step:${step.component}`);
      else if (val > this.steps.length)
        this.$root.$emit("create-party-step:done");
    },
    "party.name": {
      handler(val) {
        this.party.name = this.formatName(val);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
